import {
    urlDataAdapterProduction,
    urlDataAdapterDevelopment,
    urlApisDataAdapterDevelopment,
    urlApisDataAdapterProduction,
    urlApisFEDataAdapterProduction,
    urlApisFEDataAdapterDevelopment,
    urlApisCRDataAdapterProduction,
    urlApisCRDataAdapterDevelopment,
    urlApisFDDataAdapterProduction,
    urlApisFDDataAdapterDevelopment,
    urlApisFGDataAdapterProduction,
    urlApisFGDataAdapterDevelopment,
    urlApisFMDataAdapterProduction,
    urlApisFMDataAdapterDevelopment,
    urlApisFVDataAdapterProduction,
    urlApisFVDataAdapterDevelopment,
    urlApisFPDataAdapterProduction,
    urlApisFPDataAdapterDevelopment,
} from '../config/config.js'

export default class DataAdapter {

    constructor() {
        this.baseURL = this.fullURL() + '/api/v1/'
        this.baseApis = this.fullURLAPIS() + '/api/v1/'
        this.baseApisFE = this.fullURLAPISFE() + '/api/v1/'
        this.baseApisCR = this.fullURLAPISCR() + '/api/v1/'
        this.baseApisFG = this.fullURLAPISFG() + '/api/v1/'
        this.baseApisFM = this.fullURLAPISFM() + '/api/v1/'
        this.baseApisFV = this.fullURLAPISFV() + '/api/v1/'
        this.baseApisFP = this.fullURLAPISFP() + '/api/v1/'
        this.baseApisFD = this.fullURLAPISFD() + '/api/v1/'
        this.ultimaFechaPermisibleDeActualizacionDatos = Date.now() - (24 * 60 * 60 * 1000) // Ahorita menos un día
        this.ultimaFechaPermisibleDeActualizacionDiccionarios = Date.now() - (7 * 24 * 60 * 60 * 1000) // Ahorita menos una semana
    }

    getUrlApiapp(unidadNegocio) {
        switch (parseInt(unidadNegocio)) {
            case 1:
                return this.baseApisFD
            case 2:
                return this.baseApis
            case 3:
                return this.baseApisFV
            case 4:
                return this.baseApisFE
            case 5:
                return this.baseApisCR
            case 6:
                return this.baseApisFP
            case 7:
                return this.baseApisFG
            case 8:
                return this.baseApisFM
            default:
                return this.baseApis
        }
    }

    getUnidadNegocioHost(unidadNegocio) {
        if (window.location.hostname === 'localhost') {
            return "reportes.fahorro.biz"
        } else {
            switch (parseInt(unidadNegocio)) {
                case 1:
                    return "do.farmavalue.biz"
                case 2:
                    return "reportes.fahorro.biz"
                case 3:
                    return "ni.farmavalue.biz"
                case 4:
                    return "sv.farmavalue.biz"
                case 5:
                    return "cr.farmavalue.biz"
                case 6:
                    return "pa.farmavalue.biz"
                case 7:
                    return "gt.farmavalue.biz"
                case 8:
                    return "mx.farmavalue.biz"
                default:
                    return ""
            }
        }
    }

    fullURL = () => {
        if (window.location.hostname === 'localhost') {
            return urlDataAdapterDevelopment
        } else {
            return urlDataAdapterProduction
        }
    }


    fullURLAPIS = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisDataAdapterDevelopment
        } else {
            return urlApisDataAdapterProduction
        }
    }

    fullURLAPISFE = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFEDataAdapterDevelopment
        } else {
            return urlApisFEDataAdapterProduction
        }
    }


    fullURLAPISCR = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisCRDataAdapterDevelopment
        } else {
            return urlApisCRDataAdapterProduction
        }
    }


    fullURLAPISFD = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFDDataAdapterDevelopment
        } else {
            return urlApisFDDataAdapterProduction
        }
    }


    fullURLAPISFG = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFGDataAdapterDevelopment
        } else {
            return urlApisFGDataAdapterProduction
        }
    }


    fullURLAPISFM = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFMDataAdapterDevelopment
        } else {
            return urlApisFMDataAdapterProduction
        }
    }


    fullURLAPISFV = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFVDataAdapterDevelopment
        } else {
            return urlApisFVDataAdapterProduction
        }
    }


    fullURLAPISFP = () => {
        if (window.location.hostname === 'localhost') {
            return urlApisFPDataAdapterDevelopment
        } else {
            return urlApisFPDataAdapterProduction
        }
    }


    options = (authenticationRequired) => {
        let options = {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }

        if (authenticationRequired) {
            let token = localStorage.getItem('jwtToken')
            let authorizationHeaders = {
                "Authorization": `Bearer: ${token}`
            }
            options = { ...options, headers: { ...options.headers, ...authorizationHeaders } }
        }

        return options
    }


    doLogin(options, callBack, callBackError) {
        options = { ...this.options(false), ...options }
        return fetch(this.baseURL + `auth`, options)
            .then(res => res.json())
            .then(res => callBack(res))
            .catch(error => callBackError(error))
    }
    MicrosoftLogin(options, callBack, callBackError) {
        options = { ...this.options(false), ...options }
        return fetch(this.baseURL + `auth/microsoft`, options)
            .then(res => res.json())
            .then(res => callBack(res))
            .catch(error => callBackError(error))
    }
    generarTokenlogin(options, callBack, callBackError) {
        options = { ...this.options(false), ...options }
        return fetch(this.baseURL + `auth/generar-token`, options)
            .then(res => res.json())
            .then(res => callBack(res))
            .catch(error => callBackError(error))
    }
    loginToken(options, callBack, callBackError) {
        options = { ...this.options(false), ...options }
        return fetch(this.baseURL + `auth/token`, options)
            .then(res => res.json())
            .then(res => callBack(res))
            .catch(error => callBackError(error))
    }

    getCierresUserData(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `auth/cierres`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                }
                else {
                    callBackError({ processed: false, message: res.message })
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCierres(callBack, callBackError) {
        let options = this.options(true)
        return fetch(this.baseURL + `consolidado/cierres`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.cierres)
                }
                else {
                    callBackError({ processed: false, message: res.message })
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getMenu(callBack, callBackError) {
        let options = this.options(true)
        return fetch(this.baseURL + `auth/menu`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getReport(reporte, callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `reporte/` + reporte, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.rows)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getReportDetalleAdiuvo(reporte, callBack, callBackError, options, prd) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `reporte/` + reporte, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.rows, prd)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getFormatos(formatos, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (formatos.lastFetched !== undefined && formatos.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `formato`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ formatos: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getReportConsolidacionVentas(callBack, callBackError) {
        let options = this.options(true)
        return fetch(this.baseURL + `reporte/consolidacion-ventas`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    cargaMonitoreoPrecios(options, callBack, callBackError, prd) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `monitoreo-precios/carga`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception }, prd)
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error }, prd)
            })
    }

    // Inicio Correos CR

    getCorreosRecibidos(callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `correo/recibidos`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosAsignados(callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `correo/asignados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosAsignadosAOtros(callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `correo/asignados-a-otros`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosSinAceptar(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/sin-aceptar`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCierreCompras(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/cierre-compras`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getSucursales(sucursales, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    asignarCorreo(options, callBack, callBackError, row, sucursalId) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/asignar`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(row, sucursalId)
                else
                    callBackError({ processed: false, message: res.message })
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreoAdjunto(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/adjunto`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res)
                else
                    callBackError({ processed: false, message: res.message })
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    xmlHacienda(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/hacienda/xml`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                let messageClient = "Error al realizar solicitud. Favor reintente. Si vuelve a fallar, favor espere unos minutos y reintente"
                callBackError({ processed: false, message: error, message_client: messageClient })
            })
    }

    getxmlHaciendaConfirmado(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `correo/xml/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getxmlRespuestaHacienda(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `correo/xml-respuesta-hacienda/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    deasignar(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/deasignar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    archivarCorreo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/archivar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    guardarObservacionesAsignacionCorreo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/guardar-observaciones-asignacion`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    guardarObservacionesAceptacionCorreo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/guardar-observaciones-aceptacion`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getDetallesDeasignarCorreo(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `correo/detalles-deasignar/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosArchivados(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `correo/archivados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    desarchivarCorreo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/desarchivar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getDetallesArchivarCorreo(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `correo/detalles-archivar/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getBusquedaCorreos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/busqueda`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosBusquedaMoverFacturas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/busqueda/mover-facturas`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    cambiarSucursal(options, callBack, callBackError, row, sucursalId) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `correo/cambiar-sucursal`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(row, sucursalId)
                else
                    callBackError({ processed: false, message: res.message })
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosTrabajados(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `correo/trabajados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getCorreosEstadisticas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `correo/estadisticas`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    //Fin correos CR

    //Inicio correos DE

    getCorreosAsignadosDE(callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `de/correo/asignados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    guardarObservacionesAceptacionCorreoDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/guardar-observaciones-aceptacion`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    xmlHaciendaDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/hacienda/xml`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getxmlHaciendaConfirmadoDE(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `de/correo/xml/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreoAdjuntoDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/adjunto`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res)
                else
                    callBackError({ processed: false, message: res.message })
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    archivarCorreoDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/archivar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosArchivadosDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `de/correo/archivados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getDetallesArchivarCorreoDE(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `de/correo/detalles-archivar/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    desarchivarCorreoDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/desarchivar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCierreComprasDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/cierre-compras`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getBusquedaCorreosDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `de/correo/busqueda`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getxmlRespuestaHaciendaDE(callBack, callBackError, correoId) {
        let options = this.options(true)
        options.method = "GET"
        return fetch(this.baseURL + `de/correo/xml-respuesta-hacienda/` + correoId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getCorreosTrabajadosDE(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `de/correo/trabajados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    //Fin correos DE

    getComparacionPrecios(callBack, callBackError) {
        let options = this.options(true)

        return fetch(this.baseURL + `monitoreo-precios/comparacion`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getParapedidosPorConfirmar(callBack, callBackError, proveedor, options, ruta) {
        options = { ...this.options(true), ...options }
        let url = (ruta === 1) ? `parapedido/por-confirmar/` : `parapedido/paquetes-por-confirmar/`;
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getPaquetesProveedor(callBack, callBackError, options) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedido/paquetes-proveedor/`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getParapedidosPorConfirmarExcel(callBack, callBackError, options, ruta) {
        options = { ...this.options(true), ...options }
        let url = ruta === 1 ? `parapedido/por-confirmar-excel` : `parapedido/pedidos-paquetes/`;
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getExcelPedidos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `parapedido/generar-excel-pedidos/`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    confirmarParapedidos(options, callBack, callBackError, ruta) {
        options = { ...this.options(true), ...options }
        let url = ruta === 1 ? `parapedido/confirmar` : `parapedido/crear-pedido/`;
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res)
                else
                    callBackError(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getParapedidosPorAprobar(callBack, callBackError, options) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `parapedido/por-aprobar/`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res.pedidos)
                else
                    callBackError(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    aprobarParapedidos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `parapedido/aprobar`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res)
                else
                    callBackError(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getProveedoresPorConfirmar(proveedoresPorConfirmar, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (proveedoresPorConfirmar.lastFetched !== undefined && proveedoresPorConfirmar.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `proveedor/por-confirmar`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ proveedoresPorConfirmar: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getProveedoresPedidosPendientesAprobar(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `parapedido/proveedor/pedidos-pendientes-aprobar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.proveedores)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getEstadoServidores(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `sucursal/estado-servidores`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.rows)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getUsuarios(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `administracion/usuarios`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getUsuarioPermisos(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `usuario/permisos`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    guardarUsuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `usuario`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getDatosUsuario(usuarioId, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `usuario/datos/` + usuarioId, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    editarUsuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `usuario/editar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getListado(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        //options.method = 'GET'
        return fetch(this.baseURL + `reporte/reporte_pedidos_en_linea`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getListadoOrdenesIncompletas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/listado-ordenes-incompletas`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    cuponBuscar(options, callBack, callBackError) {
        options = { ...this.options(true), ...options };
        return fetch(this.baseURL + `cupon/busqueda`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }
    cuponCanjeado(options, callBack, callBackError) {
        options = { ...this.options(true), ...options };
        return fetch(this.baseURL + `cupon/canjeado`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    cuponCanjear(options, callBack, callBackError) {
        options = { ...this.options(true), ...options };
        return fetch(this.baseURL + `cupon/editar/canje`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    getCuponesRedimidos(options, callBack, callBackError, params) {
        options = { ...this.options(true), ...options };
        return fetch(this.baseURL + `cupon/redimidos`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.rows);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    getCuponesInventario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `cupon/inventario`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getHealthCheck(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `healthcheck-procesos`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getRecurrenciasMensuales(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `venta/recurrencia/mensuales`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.rows);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    getListadoEvaluaciones(options, callBack, callBackError) {

        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/listado-evaluaciones`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                // callBack([])
                else
                    callBack(res)
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }


    getInformacionEvaluacion(options, callBack) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/informacion_evaluacion`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    console.log(res.exception)
                else
                    callBack(res)
            })
            .catch(error => {
                return error
            })
    }

    getErroresApp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `venta/errores-app`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res);
                } else {
                    callBack(res.rows);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    getCsvs(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `csv/url`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getProveedores(proveedores, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (proveedores.lastFetched !== undefined && proveedores.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `proveedor`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ proveedores: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }


    asignacionProveedoresUsuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `proveedores/asignacion-usuario`, options)
            .then(res => res.json())
            .then(res => {
                // if (res.exception)
                //     callBackError({ processed: false, message: res.exception })
                // else {
                console.log(res)
                if (res.processed)
                    callBack(res)
                else
                    callBackError(res)

            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    cargaCodigosCupones(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `cupon/carga`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else {
                    if (res.processed)
                        callBack(res.rows)
                    else
                        callBackError(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getAllProveedores(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/proveedor/all-proveedores`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else {
                    if (res.processed)
                        callBack(res)
                    else
                        callBackError(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getIdOrdenParapedido(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedidos/id-paquetes-proveedor`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else {
                    if (res.processed)
                        callBack(res)
                    else
                        callBackError(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }


    getPremios(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `premio/clientes`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)

            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    saveClientePremio(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `premio/entrega`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res.rows)
                else
                    callBackError(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getAudios(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `audio/url`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getUnidadesDeNegocio(unidadesDeNegocio, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (unidadesDeNegocio.lastFetched !== undefined && unidadesDeNegocio.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDatos) {
            callBack()
        } else {

            return fetch(this.baseURL + `unidad_negocio`, options)
                .then(res => res.json())
                .then(res => {
                    if (res.exception)
                        callBackError({ processed: false, message: res.exception })
                    else
                        callBack({ unidadesDeNegocio: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getEmoticones(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `audio/obtener-emoticones`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getEmoticonesPalabras(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `audio/obtener-emoticones-palabras`, options)
            .then(res => res.json())
            .then(res => {
                callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    nuevoFlag(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `audio/nuevo-flag`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                }
                else {
                    callBackError({ processed: false, message: res.message })
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }



    getUbicacionesMotoristas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `motorista/posiciones`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getViajeMotorista(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `motorista/obtener-viaje`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getMotoristas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `motorista`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack({ motoristas: res })
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getMotoristasRetroalimentacionUbicaciones(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `motorista/retroalimentacion-ubicaciones`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getMotoristasEntregas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `motorista/entregas`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getInformacionMapaCalorEntregas(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `motorista/mapa-calor-entregas`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getPedidosImagen(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `reporte/pedidos-imagen`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getGrabaciones(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `grabacion/listar`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getGrabacionCriterios(grabacionCriterios, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (grabacionCriterios.lastFetched !== undefined && grabacionCriterios.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `grabacion_criterio`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ grabacionCriterios: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    // obtiene las sucursales de Farmacias del Ahorro
    getSucursalesHN(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesHN })
        } else {

            return fetch(this.baseApis + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFE(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFE })
        } else {

            return fetch(this.baseApisFE + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesCR(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesCR })
        } else {

            return fetch(this.baseApisCR + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFD(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFD })
        } else {

            return fetch(this.baseApisFD + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFG(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFG })
        } else {

            return fetch(this.baseApisFG + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFM(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFM })
        } else {

            return fetch(this.baseApisFM + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFV(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFV })
        } else {

            return fetch(this.baseApisFV + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getSucursalesFP(sucursales, callBack, callBackError) {
        let options = this.options(false)
        options.method = 'GET'
        if (sucursales.lastFetched !== undefined && sucursales.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack({ sucursales: sucursales.sucursalesFP })
        } else {

            return fetch(this.baseApisFP + `sucursal`, options)
                .then(res => res.json())
                .then(res => {
                    callBack({ sucursales: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    getEstadisticasApp(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'

        return fetch(this.baseURL + `reporte/estadisticas-app`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    herramientaPushNotifications(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `notificacion/herramienta-push-notifications`, options)
            .then(res => res.json())
            .then(res => {

                if (res.exception) {
                    callBackError({ processed: false, message: "Error, favor comunicate con el administrador" })
                    let m = `Error en el portal al enviar push notificación[BR] Nombre función: herramientaPushNotifications [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] exception: ${JSON.stringify(res.exception)}`
                    this.enviarNotificacionErrorbitrix(1, 948, m, 8888)
                }
                else {
                    if (res.processed) {
                        callBack(res)
                    }
                    else {
                        callBackError({ processed: false, message: "Error, favor comunicate con el administrador" })
                        let m = `Error en el portal al enviar push notificación [BR] Nombre función: herramientaPushNotifications [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] resultado: ${JSON.stringify(res)}`
                        this.enviarNotificacionErrorbitrix(1, 948, m, 8888)
                    }
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: "Error, favor comunicate con el administrador" })
                let m = `Error en el portal  al enviar push notificación[BR] Nombre función: herramientaPushNotifications[BR] Parametros enviados:${JSON.stringify(options.body)}[BR] error: ${JSON.stringify(error)}`
                this.enviarNotificacionErrorbitrix(1, 948, m, 8888)
            })
    }



    enviarNotificacionErrorbitrix(tipo, id, mensaje, proceso = 999) {
        let options = {
            body: JSON.stringify({
                tipo: tipo,
                id: id,
                mensaje: mensaje,
                proceso: proceso
            })
        }
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bitrix/notificacion-error`, options)
            .then(res => res.json())
            .then(res => {

            })
            .catch(error => {

            })
    }

    getParametrosApp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `parametro/listar`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    actualizarParametroApp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `parametro/actualizar`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    envioCorreoPruebaMailjet(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `mailjet/correo-prueba`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                alert("Error: " + error)
            })
    }

    herramientaSMS(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `notificacion/herramienta-sms`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: "Error, favor verifica el documento esté correcto o intenta con una menor cantidad de clientes." })
                else {
                    if (res.processed)
                        callBack(res)
                    else
                        callBackError({ processed: false, message: res.message })
                }

            })
            .catch(error => {
                callBackError({ processed: false, message: "Error, favor comunicate con el administrador" })
            })
    }

    envioCorreoMailJet(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `mailjet/enviar-correo`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    informacionClienteMail(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `mailjet/correos-usuario`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    getTemplatesMandrill(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `mailjet/lista-templates`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.processed) {
                    callBack(res.message)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }

    envioWhatsappMasivoPrueba(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/whatsapp/mensaje-prueba/`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                }
                else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    informacionClienteWhatsapp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `whatsapp/informacion-usuarios`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message, 1)
                }
                else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    listarInstanciasWhatsapp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `whatsapp/instancias`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    enviarMensajeMasivo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `whatsapp/enviar-mensaje`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }

    uploadWhatsappImagen(options, url, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/whatsapp/upload-imagen/`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res, url)
                }
                else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    motoristaViajesAsignados(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/motorista/viajes-asignados`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res.message);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }
    motoristaSmsLog(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/motorista/get-codigos`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res.message);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }

    getProcesosSincronizacionApp(procesosSincronizacionApp, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        if (procesosSincronizacionApp && procesosSincronizacionApp.lastFetched !== undefined && procesosSincronizacionApp.lastFetched >= this.ultimaFechaPermisibleDeActualizacionDiccionarios) {
            callBack()
        } else {

            return fetch(this.baseURL + `app/procesos`, options)
                .then(res => res.json())
                .then(res => {
                    if (res.exception)
                        callBackError({ processed: false, message: res.exception })
                    else
                        callBack({ procesosSincronizacionApp: res })
                })
                .catch(error => {
                    callBackError(error)
                })
        }
    }

    sincronizarProcesoApp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `app/proceso`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: "Error, favor comunicate con el administrador." })
                    let m = `Error en el portal al sincronizar procesos [BR] Nombre función: sincronizarProcesoApp [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] exception: ${JSON.stringify(res.exception)}`
                    this.enviarNotificacionErrorbitrix(1, 948, m, 8887)
                }
                else {
                    if (res.processed) {
                        callBack(res)
                    }
                    else {
                        callBackError({ processed: false, message: `Error, favor comunicate con el administrador. ${res.message}` })
                        let m = `Error en el portal al sincronizar procesos [BR] Nombre función: sincronizarProcesoApp [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] resultado: ${JSON.stringify(res)}`
                        this.enviarNotificacionErrorbitrix(1, 948, m, 8887)
                    }
                }

            })
            .catch(error => {
                callBackError({ processed: false, message: "Error, favor comunicate con el administrador." })
                let m = `Error en el portal al sincronizar procesos [BR] Nombre función: sincronizarProcesoApp [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] Error: ${JSON.stringify(error)}`
                this.enviarNotificacionErrorbitrix(1, 948, m, 8887)
            })
    }


    //Validar Correos Electronicos bouncer 
    //Validar Correos Electronicos bouncer 
    //Validar Correos Electronicos bouncer 
    validarCorreoElectronico(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bouncer/validar-correo`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                alert("Error: " + error)
            })
    }

    validarLoteDecorreos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bouncer/validar-lote`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    creditosDisponiblesBouncer(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'POST'
        return fetch(this.baseURL + `/bouncer/verificar-creditos`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res)
                } else {
                    callBackError(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    informacionClientesBouncer(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bouncer/correos-usuario`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    listarLotes(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bouncer/listar-lote`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    ListarCorreos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/bouncer/listar-correos-validados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    actualizarLote(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        options.method = 'POST'
        return fetch(this.baseURL + `/bouncer/actualizar-lote`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    getCorreosValidadosPorLote(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        options.method = 'POST'
        return fetch(this.baseURL + `/bouncer/listar-resultado-lote`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    //Validar Correos Electronicos bouncer 
    //Validar Correos Electronicos bouncer 
    //Validar Correos Electronicos bouncer 



    getDatosHealthcheck(id, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `healthcheck-datos/` + id, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res.message)
                else
                    callBackError({ processed: false, message: `Error, al obtener la información del proceso. ${res.message}` })
            })
            .catch(error => {
                callBackError(error)
            })
    }
    editarInfoHealthcheck(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `healthcheck/update/`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed)
                    callBack(res.message)
                else
                    callBackError({ message: `Error al ingresar la información. ${res.message}` })
            })
            .catch(error => {
                callBackError(error)
            })
    }
    getPedidosLogUnidad(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedido/parapedidos-logs-unidad`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    pedidosDashboard(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedido/dashboard/`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    enviarCorreoPedidosEnLineaProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedido/solicutud-pedidos-correo/`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    reiniciarPedidosProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/parapedido/reset-confirmado-proveedor`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }

    getLaboratoriosPorProveedorParapedidos(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/proveedor/laboratorio-producto`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.processed)
                    callBack(res.message)
                else
                    callBackError(res.message)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }


    //Push notification Logs
    //Push notification Logs
    getPushNotificationLogs(options, callBack, callBackError) {

        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/listado-push-notification`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: res.exception })
                    let m = `Error al listar push notificación[BR]función getPushNotificationLogs [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] exeptión: ${JSON.stringify(res.exception)}`
                    this.enviarNotificacionErrorbitrix(1, 948, m, 9999)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
                let m = `Error al listar push notificación[BR]función getPushNotificationLogs [BR] Parametros enviados:${JSON.stringify(options.body)}[BR] error: ${JSON.stringify(error)}`
                this.enviarNotificacionErrorbitrix(1, 948, m, 9999)
            })
    }
    cancelarPushNotificacionProgramada(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/notificacion/cancelar-push-notificacion-programada`, options)
            .then(res => res.json())
            .then(res => {

                if (!res.processed) {
                    callBackError(res)
                }
                else {

                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }


    //Push notification Logs
    //Push notification Logs
    //Push Bitacora sms
    //Push Bitacora sms
    getBitacoraSms(options, callBack, callBackError) {

        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/listado-sms-enviados`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: res.exception })
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }
    cancelarEnvioSms(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/notificacion/cancelar-sms-programados`, options)
            .then(res => res.json())
            .then(res => {

                if (!res.processed) {
                    callBackError(res)
                }
                else {

                    callBack(res.message)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    //Push Bitacora sms
    //Push Bitacora sms
    gerPermisoPortalReclutamiento(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }

        return fetch(this.baseURL + `/reclutamiento/acceso`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res)
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getListadoParametrosAppPais(callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `/reporte/parametro-app-pais`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    //Push notification Logs
    //Push notification Logs
    //get reporte consulta de precios cliente.
    getLogsClientePrecio(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/consulta-precio-cliente`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: res.exception })
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }

    getProductosPaises(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reporte/productos-pais`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: res.exception })
                }
                else {
                    callBack(res)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }
    // *************************************************
    // *************************************************
    // Funciones para administrar los grupos de teams
    // Funciones para administrar los grupos de teams
    getGruposTeams(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `teams/grupos`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) { callBackError({ processed: false, message: res.exception }) }
                else {
                    res.processed === true ? callBack(res.message) : callBackError(res.message)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }

    guardarGrupoTeams(options, url, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }

    getGrupoTeams(id, callBack, callBackError) {
        let options = this.options(true)
        options.method = 'GET'
        return fetch(this.baseURL + `teams/grupo/` + id, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }

    // Funciones para administrar los grupos de teams
    // Funciones para administrar los grupos de teams
    // *************************************************
    // *************************************************
    // Funciones para administrar ws Proveedors
    // Funciones para administrar ws Proveedors
    getWsProveedores(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `ws-proveedor/all`, options)
            .then(res => res.json())
            .then(res => {
                if (res.exception) {
                    callBackError({ processed: false, message: res })
                }
                else {
                    res.processed === true ? callBack(res.message) : callBackError(res.message)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }

    guardarWsProveedor(options, url, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }

    getWsProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `ws-proveedor/informacion/`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                callBackError({ processed: false, message: error })
            })
    }
    getProveedoresPais(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `ws-proveedor/proveedores-pais/`, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getItemProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `ws-proveedor/items-proveedor/`, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    guardarItemsProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "ws-proveedor/asignar-items", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getWsUsuarios(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "ws-proveedor/usuarios", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getProveedoresUsuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "ws-proveedor/proveedores-usuario", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    guardarProveedoresusuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "ws-proveedor/asignar-proveedores", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getProveedoresCupones(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `cupon/proveedores`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res.message)
            })
            .catch(error => {
                callBackError(error)
            })
    }

    getProveedoresCuponesAsignados(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `cupon/proveedor-cupones`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res.message)
            })
            .catch(error => {
                callBackError(error)
            })
    }
    reportesApp(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reportes/app`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }
    getReportesApp(callBack, callBackError) {
        return fetch(this.baseURL + `/reportes/app/reportes`)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception) {
                    callBackError({ processed: false, message: res.exception })
                } else {
                    callBack(res)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    // Funciones para administrar ws Proveedors
    // Funciones para administrar ws Proveedors


    // Funciones para enlaces publicidad
    // Funciones para enlaces publicidad
    linkPublicidadCrear(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "/link/crear", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getLinkPublicidad(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `link/publicidad`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception) {
                    callBackError({ processed: false, message: res })
                }
                else {
                    res.processed === true ? callBack(res.message) : callBackError(res.message)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }
    getEstadisticasLink(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        options.method = 'POST'
        return fetch(this.baseURL + `/link/estadistica-publicidad`, options)
            .then(res => res.json())
            .then(res => {
                if (res.processed) {
                    callBack(res.message)
                }
                else {
                    console.log(res)
                    callBackError(res.message)
                }
            })
            .catch(error => {
                callBackError(error)
            })
    }
    // Funciones para enlaces publicidad
    // Funciones para enlaces publicidad
    // Funciones para log carga imagen
    // Funciones para log carga imagen
    logCargaImagen(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "/cargaImagen/crear", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    getLogsCargaImagen(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `cargaImagen/logs`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception) {
                    callBackError({ processed: false, message: res })
                }
                else {
                    res.processed === true ? callBack(res.message) : callBackError(res.message)
                }
            })
            .catch(error => {
                // callBack([])
                callBackError({ processed: false, message: error })
            })
    }
    // Funciones para log carga imagen
    // Funciones para log carga imagen

    //Token push notificacion usuario
    //Token push notificacion usuario
    usuariopPushToken(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/datasource/push-token-usuario`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res.message);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }
    buscarProductoPais(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/datasource/buscar-producto-app`, options)
            .then(res => res.json())
            .then(res => {
                if (!res.processed) {
                    callBackError(res.message);
                } else {
                    callBack(res.message);
                }
            })
            .catch(error => {
                callBackError({ processed: false, message: error });
            });
    }
    //Token push notificacion usuario
    //Token push notificacion usuario


    guardarPlantillaCorreo(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "/mailjet/crear-template", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    eliminarPlantilla(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/mailjet/borrar-template`, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }

    reportesAppPedidosProveedor(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/reportes/pedidos-fecha-proveedor`, options)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.exception)
                    callBackError({ processed: false, message: res.exception })
                else
                    callBack(res)
            })
            .catch(error => {
                callBackError(error)
            })
    }


    getInformacionHealthcheckAsignados(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + `/healthcheck/asignados-usuario`, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })
    }
    guardarHealthcheckUsuario(options, callBack, callBackError) {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + "healthcheck/asignar", options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res.message) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                callBackError({ processed: false, message: error })
            })

    }

    //  ################################################################   
    //  ################################################################   
    GetInformacion(url, options, callBack, callBackError, method = "POST") {
        options = { ...this.options(true), ...options }
        return fetch(this.baseURL + url, options)
            .then(res => res.json())
            .then(res => {
                res.processed === true ? callBack(res) : callBackError(res.message)
            })
            .catch(error => {
                console.log(error)
                console.log("Error en GetInformacion")
                callBackError({ processed: false, message: error })
            })
    }
    //  ################################################################   
    //  ################################################################   

}